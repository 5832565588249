import React from 'react';
import { PageLayout } from '../components/pageLayout';
import { Flex, Heading, Text, Box, Image, css } from '@chakra-ui/core';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import linkResolver from '../utils/linkResolver';
import { StyledRichText } from '../components/StyledRichText';

export const query = graphql`
	query {
		prismic {
			allOur_storys {
				edges {
					node {
						founding_team {
							name
							img
							bio
						}
						story_title
						story_subtitle
						our_story_body
						panels {
							img
							text
						}
						promise {
							description
							value
						}
					}
				}
			}
		}
	}
`;

const ourStory = ({ data }) => {
	const prismicContent = data.prismic!.allOur_storys.edges[0]!.node;
	const panels = prismicContent.panels;
	const foundingTeam = prismicContent.founding_team;
	const promise = prismicContent.promise;

	// console.log(prismicContent);

	// let arr = [];

	return (
		<PageLayout>
			<Flex flexDir="column">
				<Box w="100">
					{panels &&
						panels.map((el, i) => {
							let isOdd = i + (1 % 2) == 1 ? true : false;
							return (
								<Flex flexDir="row" maxH="min-content" w="100%" key={i}>
									<Flex
										w={{ base: 1 / 3, md: 1 / 2 }}
										backgroundImage={`
                                            url('${el.img.url}')
                                        `}
										backgroundSize="cover"
										bgPos="center"
										order={isOdd ? 0 : 1}
									></Flex>
									<Flex
										w={{ base: 2 / 3, md: 1 / 2 }}
										p={{ base: 10, md: 20 }}
										bg="#f7f0e3"
										alignItems="center"
										justifyContent="center"
									>
										<Box
											css={css`
												h1 {
													font-size: 3rem !important;
													font-weight: 700;
												}
												max-width: 75ch;
											`}
										>
											<StyledRichText
												render={el.text}
												linkResolver={linkResolver}
											/>
										</Box>
									</Flex>
								</Flex>
							);
						})}
				</Box>
				<Flex
					justifyContent={{ base: 'start', md: 'center' }}
					p={10}
					flexWrap="wrap"
				>
					<Box w="200px" mr={3} mb={8}>
						<Heading size="xl">{prismicContent.story_title[0].text}</Heading>
						<StyledRichText
							render={prismicContent.story_subtitle}
							linkResolver={linkResolver}
						/>
						{/* 
						<Heading size="md" mt={4}>
							Feature one
						</Heading>
						<Text mt={4}>
							Separated they live in Bookmarksgrove right at the coast of the
							famous Semantics, large language
						</Text> 
						*/}
					</Box>
					<Box w="500px">
						<StyledRichText
							render={prismicContent.our_story_body}
							linkResolver={linkResolver}
						/>
					</Box>
				</Flex>
				<Flex justifyContent="center" bg="#f7f0e3" w="100%" py={12}>
					<Flex flexDir="column" overflow="hidden">
						{/* <Box w="200px"> */}
						<Heading size="xl">Our Founding Team</Heading>
						<Text>Our commitment to you</Text>
						<Flex flexWrap="wrap" flexDir={{ base: 'column', md: 'row' }}>
							{foundingTeam &&
								foundingTeam.map((el, i) => {
									return (
										<Box
											w="300px"
											minW="280px"
											py={4}
											// pl={i === 0 ? 0 : null}
											pr={{ base: 0, md: 6 }}
											key={i}
										>
											{el.img && (
												<Box
													bgImage={`url('${el.img.url}')`}
													height="197px"
													rounded="4px"
													backgroundSize="cover"
													bgPos="center"
												></Box>
											)}
											<Heading size="lg" mt={3}>
												{el.name}
											</Heading>
											<Text>{el.bio[0].text}</Text>
										</Box>
									);
								})}
						</Flex>
						<Heading size="md" mt={4}></Heading>
						{/* </Box> */}
					</Flex>
				</Flex>
				<Flex justifyContent="center" w="100%" py={12} px={5}>
					<Flex maxWidth="1000px" flexDir="column" overflow="hidden">
						<Heading size="xl">Our Promise</Heading>
						<Text>Our commitment to you</Text>
						<Flex overflow="scroll">
							{promise &&
								promise.map((el, i) => {
									return (
										<Box
											w="350px"
											minW="280px"
											p={4}
											pl={i === 0 ? 0 : null}
											key={'pm' + i}
										>
											<Heading size="lg" mt={3}>
												{el.value}
											</Heading>
											<Text mt={2}>{el.description[0].text}</Text>
										</Box>
									);
								})}
						</Flex>
						<Heading size="md" mt={4}></Heading>
						{/* </Box> */}
					</Flex>
				</Flex>
			</Flex>
		</PageLayout>
	);
};

export default ourStory;
